<template>
  <Main :qrcode="true">
    <Header checked="scene"></Header>
    <Banner></Banner>
    <div class="background">
      <Block>
        <div class="middle">
          <div class="title-group">
            <div class="title">展会现场展示</div>
            <div class="subtitle">Exhibition</div>
          </div>
          <div class="img-block">
            <el-card shadow="hover" v-for="(item, index) in images" :key="index" class="img-item" :body-style="{padding: 0}">
              <!-- <el-image fit="contain" :src="item.pic_url" class="img" lazy :preview-src-list="previewList"></el-image> -->
              <image-ch fit="contain" :src="item.pic_url" class="img" lazy :preview-src-list="previewList" :imageIndex="index"></image-ch>
            </el-card>
          </div>
        </div>
      </Block>
    </div>
    <Footer></Footer>
</Main>
</template>

<script>
import Main from '@/components/layout2/layout2-main.vue';
import Header from '@/components/layout2/layout2-header.vue';
import Footer from '@/components/layout2/layout2-footer.vue';
import Banner from '@/components/layout2/layout2-banner.vue';
import Block from '@/components/layout2/layout2-block.vue'
import { list } from '@/api/scene'
import ImageCh from '@/components/image'

export default{
  name: 'introduction',
  components: {
    Main,
    Header,
    Footer,
    Banner,
    Block,
    ImageCh
  },
  data() {
    return {
      images: [],
      page: 1,
      previewList: []
    }
  },
  created() {
    document.title = this.$t("common.title8");
    this.getList()
  },
  methods: {
    getList() {
      list()
        .then(res => {
          if (res.code == 0) {
            this.images = res.data
            this.previewList = []
            this.images.forEach(item => {
              this.previewList.push(item.origin_pic_url)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.middle{
  width: 100%;
  margin-bottom: 60px;
}
.title-group{
  padding: 40px 0 20px;
  text-align: center;

  .title{
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .subtitle{
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #999999;
  }
}
.img-block{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .img-item{
    width: 390px;
    height: 293px;
    box-sizing: border-box;
    margin-top: 10px;
    .img{
      width: 390px;
      height: 293px;
    }
  }
  .img-item:not(:nth-child(3n)){
    margin-right: 15px;
  }
  ::v-deep .el-card.is-hover-shadow:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }
}
</style>