<template>
    <el-carousel height="500px" :autoplay="true" arrow="never" class="carousel-container">
      <el-carousel-item>
        <div class="bg"></div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="bg2"></div>
      </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
    name: 'layout2-banner',
    components: {
        
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    watch: {
       
    }
}
</script>
<style lang="less" scoped>
.carousel-container{
  width: 100%;
  .carousel-img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.bg{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width:1200px;
    height: 500px;
    background: url(~@/assets/img/banner.png) no-repeat center top #3053A3;
}
.bg2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width:1200px;
    height: 500px;
    background: url(~@/assets/img/banner2.jpg) no-repeat center top;
}
</style>
